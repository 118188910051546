import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { ShipmentSearchQuery, UserDataSources_$reflection, User_$reflection, Account_$reflection, PagedDataResponse$1_$reflection, ShipmentSearchItem_$reflection, ShipmentSearchQuery_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { int32_type, class_type, bool_type, lambda_type, record_type, array_type, unit_type, union_type, string_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { toInt32, equals, fromInt32, toInt64, compare } from "../fable_modules/fable-library.4.10.0/BigInt.js";
import { defaultOf, createObj, comparePrimitives, equals as equals_1 } from "../fable_modules/fable-library.4.10.0/Util.js";
import { map as map_1, sortBy } from "../fable_modules/fable-library.4.10.0/Array.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { iterate, ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Typeahead_render, Typeahead_setInputValue, Typeahead_init } from "../Components/Typeahead.fs.js";
import { getConsignees, getShippers, getLocations } from "../Api/DataSourceApi.fs.js";
import { SearchType_Entity, Location_LocationType, Location_Entity } from "../../CView.DTOs/Constants.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { export$, search } from "../Api/ShipmentApi.fs.js";
import { defaultShipmentSearchQuery } from "../Domain/Shipment.fs.js";
import { DateTimeOffset_fromString, String_defaultValue } from "../../CView.DTOs/PrimitiveTypes.fs.js";
import { compare as compare_1, join, isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { empty, map as map_2, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { maxValue, minValue, toString } from "../fable_modules/fable-library.4.10.0/Date.js";
import { formatInt, toDateTimeOffsetInput } from "../Domain/Formatter.fs.js";
import { toNullable } from "../fable_modules/fable-library.4.10.0/Option.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { render as render_2 } from "../Components/ShipmentInfo.fs.js";
import { defaultOf as defaultOf_1 } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(ShipmentSearchQuery, PreviousShipmentSearchQuery, ShipmentsDeferred, ExportShipments, Accounts, User, UserDataSources) {
        super();
        this.ShipmentSearchQuery = ShipmentSearchQuery;
        this.PreviousShipmentSearchQuery = PreviousShipmentSearchQuery;
        this.ShipmentsDeferred = ShipmentsDeferred;
        this.ExportShipments = ExportShipments;
        this.Accounts = Accounts;
        this.User = User;
        this.UserDataSources = UserDataSources;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.Shipments.State", [], State, () => [["ShipmentSearchQuery", ShipmentSearchQuery_$reflection()], ["PreviousShipmentSearchQuery", ShipmentSearchQuery_$reflection()], ["ShipmentsDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(ShipmentSearchItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(ShipmentSearchItem_$reflection())]], [["ErrorValue", string_type]]]))], ["ExportShipments", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["Accounts", array_type(Account_$reflection())], ["User", User_$reflection()], ["UserDataSources", UserDataSources_$reflection()]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ShipmentQueryChanged", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.Pages.Shipments.Intent", [], Intent, () => [[["Item", ShipmentSearchQuery_$reflection()]], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateShipmentSearchQuery", "SearchShipments", "SetSelectedOrigin", "SetSelectedDestination", "SetSelectedPortOfLading", "SetSelectedPortOfDischarge", "SetSelectedConsignee", "SetSelectedShipper", "ClearSearch", "ExportShipments", "SetPageNumber"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.Shipments.Msg", [], Msg, () => [[["Item", lambda_type(ShipmentSearchQuery_$reflection(), ShipmentSearchQuery_$reflection())]], [["Item1", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(ShipmentSearchItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(ShipmentSearchItem_$reflection())]], [["ErrorValue", string_type]]]))], ["Item2", bool_type]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [["Item", class_type("CView.UI.Models.IDataSourceItem")]], [], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", int32_type]]]);
}

function hasPreviousPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            return result.fields[0].Page > 1;
        }
        else {
            return false;
        }
    }, state.ShipmentsDeferred);
}

function hasNextPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            const pagedData = result.fields[0];
            return compare(toInt64(fromInt32(pagedData.Page * pagedData.PageSize)), pagedData.TotalCount) < 0;
        }
        else {
            return false;
        }
    }, state.ShipmentsDeferred);
}

function isExportDisabled(state) {
    if (Deferred_exists((result) => {
        if (result.tag === 0) {
            return equals(result.fields[0].TotalCount, toInt64(fromInt32(0)));
        }
        else {
            return false;
        }
    }, state.ShipmentsDeferred)) {
        return true;
    }
    else {
        return equals_1(state.ShipmentsDeferred, new Deferred$1(1, []));
    }
}

function serviceLevelSearch(state) {
    const serviceLevels = sortBy((x) => x.Name, state.UserDataSources.ServiceLevels, {
        Compare: comparePrimitives,
    });
    const matchValue = state.ShipmentSearchQuery.ModeOfTransport;
    switch (matchValue) {
        case "Air":
            return serviceLevels.filter((x_2) => x_2.IsAir);
        case "Ground":
            return serviceLevels.filter((x_3) => x_3.IsGround);
        case "Ocean":
            return serviceLevels.filter((x_4) => x_4.IsOcean);
        default:
            return new Array(0);
    }
}

export function init(accounts, user, shipmentSearchQuery, userDataSources) {
    const accountIds = map_1((dataSourceItem) => dataSourceItem.Id, accounts);
    return [new State(shipmentSearchQuery, shipmentSearchQuery, new Deferred$1(0, []), new Deferred$1(0, []), accounts, user, userDataSources), Cmd_batch(singleton(singleton((dispatch) => {
        dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
    })))];
}

export function update(msg, state) {
    let accountIds, bind$0040, bind$0040_1, bind$0040_2, bind$0040_3, x_3, bind$0040_4, x_5, bind$0040_5, x_7, bind$0040_6, x_9, x_10, bind$0040_7, bind$0040_8, bind$0040_9;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.ShipmentSearchQuery, msg.fields[1] ? state.PreviousShipmentSearchQuery : state.ShipmentSearchQuery, new Deferred$1(2, [msg.fields[0].fields[0]]), state.ExportShipments, state.Accounts, state.User, state.UserDataSources), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Search Shipments", Alert_text(msg.fields[0].fields[0].fields[0]))) : ((accountIds = map_1((dataSourceItem) => dataSourceItem.Id, state.Accounts), Cmd_batch(ofArray([Typeahead_init("shipment_place_of_receipt", (searchCriteria) => getLocations(new Location_Entity(0, []), new Location_LocationType(0, []), accountIds, searchCriteria), (Item_1) => (new Msg(2, [Item_1])), 100, 1), Typeahead_init("shipment_final_destination", (searchCriteria_1) => getLocations(new Location_Entity(0, []), new Location_LocationType(1, []), accountIds, searchCriteria_1), (Item_2) => (new Msg(3, [Item_2])), 100, 1), Typeahead_init("shipment_port_of_lading", (searchCriteria_2) => getLocations(new Location_Entity(0, []), new Location_LocationType(2, []), accountIds, searchCriteria_2), (Item_3) => (new Msg(4, [Item_3])), 100, 1), Typeahead_init("shipment_port_of_discharge", (searchCriteria_3) => getLocations(new Location_Entity(0, []), new Location_LocationType(3, []), accountIds, searchCriteria_3), (Item_4) => (new Msg(5, [Item_4])), 100, 1), Typeahead_init("shipment_shipper", (searchCriteria_4) => getShippers(new SearchType_Entity(0, []), accountIds, searchCriteria_4), (Item_5) => (new Msg(7, [Item_5])), 100, 1), Typeahead_init("shipment_consignee", (searchCriteria_5) => getConsignees(new SearchType_Entity(0, []), accountIds, searchCriteria_5), (Item_6) => (new Msg(6, [Item_6])), 100, 1)])))), new Intent(1, [])];
            }
            else if (equals_1(state.ShipmentsDeferred, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, new Deferred$1(1, []), state.ExportShipments, state.Accounts, state.User, state.UserDataSources), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, search, msg.fields[1] ? ((bind$0040 = state.PreviousShipmentSearchQuery, new ShipmentSearchQuery(bind$0040.TrackingNumber, bind$0040.ModeOfTransport, bind$0040.ServiceLevel, bind$0040.PlaceOfReceiptCode, bind$0040.PlaceOfReceiptName, bind$0040.FinalDestinationCode, bind$0040.FinalDestinationName, bind$0040.PortOfLadingCode, bind$0040.PortOfLadingName, bind$0040.PortOfDischargeCode, bind$0040.PortOfDischargeName, map_1((x) => x.Id, state.Accounts), bind$0040.HasException, bind$0040.HasPotentialException, bind$0040.SelectedShipmentDate, bind$0040.ShipmentStatus, bind$0040.Shipper, bind$0040.Consignee, bind$0040.ShipmentFromDate, bind$0040.ShipmentToDate, bind$0040.Page, bind$0040.PageSize))) : ((bind$0040_1 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_1.TrackingNumber, bind$0040_1.ModeOfTransport, bind$0040_1.ServiceLevel, bind$0040_1.PlaceOfReceiptCode, bind$0040_1.PlaceOfReceiptName, bind$0040_1.FinalDestinationCode, bind$0040_1.FinalDestinationName, bind$0040_1.PortOfLadingCode, bind$0040_1.PortOfLadingName, bind$0040_1.PortOfDischargeCode, bind$0040_1.PortOfDischargeName, map_1((x_1) => x_1.Id, state.Accounts), bind$0040_1.HasException, bind$0040_1.HasPotentialException, bind$0040_1.SelectedShipmentDate, bind$0040_1.ShipmentStatus, bind$0040_1.Shipper, bind$0040_1.Consignee, bind$0040_1.ShipmentFromDate, bind$0040_1.ShipmentToDate, 1, bind$0040_1.PageSize))), (arg) => (new Msg(1, [new AsyncMsg$1(1, [arg]), msg.fields[1]]))), new Intent(1, [])];
            }
        case 8: {
            iterate((value_1) => {
                Typeahead_setInputValue(value_1, "");
            }, ofArray(["shipment_place_of_receipt", "shipment_final_destination", "shipment_port_of_lading", "shipment_port_of_discharge", "shipment_consignee", "shipment_shipper"]));
            const newState_1 = new State(defaultShipmentSearchQuery, state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources);
            return [newState_1, singleton((dispatch) => {
                dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
            }), new Intent(0, [newState_1.ShipmentSearchQuery])];
        }
        case 9:
            if (msg.fields[0].tag === 1) {
                return [new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, new Deferred$1(2, [msg.fields[0].fields[0]]), state.Accounts, state.User, state.UserDataSources), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Export Track And Trace", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals_1(state.ExportShipments, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.ShipmentSearchQuery, state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, new Deferred$1(1, []), state.Accounts, state.User, state.UserDataSources), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, export$, (bind$0040_2 = state.PreviousShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_2.TrackingNumber, bind$0040_2.ModeOfTransport, bind$0040_2.ServiceLevel, bind$0040_2.PlaceOfReceiptCode, bind$0040_2.PlaceOfReceiptName, bind$0040_2.FinalDestinationCode, bind$0040_2.FinalDestinationName, bind$0040_2.PortOfLadingCode, bind$0040_2.PortOfLadingName, bind$0040_2.PortOfDischargeCode, bind$0040_2.PortOfDischargeName, map_1((x_2) => x_2.Id, state.Accounts), bind$0040_2.HasException, bind$0040_2.HasPotentialException, bind$0040_2.SelectedShipmentDate, bind$0040_2.ShipmentStatus, bind$0040_2.Shipper, bind$0040_2.Consignee, bind$0040_2.ShipmentFromDate, bind$0040_2.ShipmentToDate, bind$0040_2.Page, bind$0040_2.PageSize)), (arg_2) => (new Msg(9, [new AsyncMsg$1(1, [arg_2])]))), new Intent(1, [])];
            }
        case 2:
            return [new State((bind$0040_3 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_3.TrackingNumber, bind$0040_3.ModeOfTransport, bind$0040_3.ServiceLevel, (x_3 = msg.fields[0], (x_3.Value == null) ? String_defaultValue() : x_3.Value), msg.fields[0].DisplayValue, bind$0040_3.FinalDestinationCode, bind$0040_3.FinalDestinationName, bind$0040_3.PortOfLadingCode, bind$0040_3.PortOfLadingName, bind$0040_3.PortOfDischargeCode, bind$0040_3.PortOfDischargeName, bind$0040_3.AccountIds, bind$0040_3.HasException, bind$0040_3.HasPotentialException, bind$0040_3.SelectedShipmentDate, bind$0040_3.ShipmentStatus, bind$0040_3.Shipper, bind$0040_3.Consignee, bind$0040_3.ShipmentFromDate, bind$0040_3.ShipmentToDate, bind$0040_3.Page, bind$0040_3.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources), Cmd_none(), new Intent(1, [])];
        case 3:
            return [new State((bind$0040_4 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_4.TrackingNumber, bind$0040_4.ModeOfTransport, bind$0040_4.ServiceLevel, bind$0040_4.PlaceOfReceiptCode, bind$0040_4.PlaceOfReceiptName, (x_5 = msg.fields[0], (x_5.Value == null) ? String_defaultValue() : x_5.Value), msg.fields[0].DisplayValue, bind$0040_4.PortOfLadingCode, bind$0040_4.PortOfLadingName, bind$0040_4.PortOfDischargeCode, bind$0040_4.PortOfDischargeName, bind$0040_4.AccountIds, bind$0040_4.HasException, bind$0040_4.HasPotentialException, bind$0040_4.SelectedShipmentDate, bind$0040_4.ShipmentStatus, bind$0040_4.Shipper, bind$0040_4.Consignee, bind$0040_4.ShipmentFromDate, bind$0040_4.ShipmentToDate, bind$0040_4.Page, bind$0040_4.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources), Cmd_none(), new Intent(1, [])];
        case 4:
            return [new State((bind$0040_5 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_5.TrackingNumber, bind$0040_5.ModeOfTransport, bind$0040_5.ServiceLevel, bind$0040_5.PlaceOfReceiptCode, bind$0040_5.PlaceOfReceiptName, bind$0040_5.FinalDestinationCode, bind$0040_5.FinalDestinationName, (x_7 = msg.fields[0], (x_7.Value == null) ? String_defaultValue() : x_7.Value), msg.fields[0].DisplayValue, bind$0040_5.PortOfDischargeCode, bind$0040_5.PortOfDischargeName, bind$0040_5.AccountIds, bind$0040_5.HasException, bind$0040_5.HasPotentialException, bind$0040_5.SelectedShipmentDate, bind$0040_5.ShipmentStatus, bind$0040_5.Shipper, bind$0040_5.Consignee, bind$0040_5.ShipmentFromDate, bind$0040_5.ShipmentToDate, bind$0040_5.Page, bind$0040_5.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources), Cmd_none(), new Intent(1, [])];
        case 5:
            return [new State((bind$0040_6 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_6.TrackingNumber, bind$0040_6.ModeOfTransport, bind$0040_6.ServiceLevel, bind$0040_6.PlaceOfReceiptCode, bind$0040_6.PlaceOfReceiptName, bind$0040_6.FinalDestinationCode, bind$0040_6.FinalDestinationName, bind$0040_6.PortOfLadingCode, bind$0040_6.PortOfLadingName, (x_9 = msg.fields[0], (x_9.Value == null) ? String_defaultValue() : x_9.Value), (x_10 = msg.fields[0], isNullOrWhiteSpace(x_10.DisplayValue) ? String_defaultValue() : x_10.DisplayValue), bind$0040_6.AccountIds, bind$0040_6.HasException, bind$0040_6.HasPotentialException, bind$0040_6.SelectedShipmentDate, bind$0040_6.ShipmentStatus, bind$0040_6.Shipper, bind$0040_6.Consignee, bind$0040_6.ShipmentFromDate, bind$0040_6.ShipmentToDate, bind$0040_6.Page, bind$0040_6.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources), Cmd_none(), new Intent(1, [])];
        case 6:
            return [new State((bind$0040_7 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_7.TrackingNumber, bind$0040_7.ModeOfTransport, bind$0040_7.ServiceLevel, bind$0040_7.PlaceOfReceiptCode, bind$0040_7.PlaceOfReceiptName, bind$0040_7.FinalDestinationCode, bind$0040_7.FinalDestinationName, bind$0040_7.PortOfLadingCode, bind$0040_7.PortOfLadingName, bind$0040_7.PortOfDischargeCode, bind$0040_7.PortOfDischargeName, bind$0040_7.AccountIds, bind$0040_7.HasException, bind$0040_7.HasPotentialException, bind$0040_7.SelectedShipmentDate, bind$0040_7.ShipmentStatus, bind$0040_7.Shipper, msg.fields[0].DisplayValue, bind$0040_7.ShipmentFromDate, bind$0040_7.ShipmentToDate, bind$0040_7.Page, bind$0040_7.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources), Cmd_none(), new Intent(1, [])];
        case 7:
            return [new State((bind$0040_8 = state.ShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_8.TrackingNumber, bind$0040_8.ModeOfTransport, bind$0040_8.ServiceLevel, bind$0040_8.PlaceOfReceiptCode, bind$0040_8.PlaceOfReceiptName, bind$0040_8.FinalDestinationCode, bind$0040_8.FinalDestinationName, bind$0040_8.PortOfLadingCode, bind$0040_8.PortOfLadingName, bind$0040_8.PortOfDischargeCode, bind$0040_8.PortOfDischargeName, bind$0040_8.AccountIds, bind$0040_8.HasException, bind$0040_8.HasPotentialException, bind$0040_8.SelectedShipmentDate, bind$0040_8.ShipmentStatus, msg.fields[0].DisplayValue, bind$0040_8.Consignee, bind$0040_8.ShipmentFromDate, bind$0040_8.ShipmentToDate, bind$0040_8.Page, bind$0040_8.PageSize)), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources), Cmd_none(), new Intent(1, [])];
        case 10:
            return [new State(state.ShipmentSearchQuery, (bind$0040_9 = state.PreviousShipmentSearchQuery, new ShipmentSearchQuery(bind$0040_9.TrackingNumber, bind$0040_9.ModeOfTransport, bind$0040_9.ServiceLevel, bind$0040_9.PlaceOfReceiptCode, bind$0040_9.PlaceOfReceiptName, bind$0040_9.FinalDestinationCode, bind$0040_9.FinalDestinationName, bind$0040_9.PortOfLadingCode, bind$0040_9.PortOfLadingName, bind$0040_9.PortOfDischargeCode, bind$0040_9.PortOfDischargeName, bind$0040_9.AccountIds, bind$0040_9.HasException, bind$0040_9.HasPotentialException, bind$0040_9.SelectedShipmentDate, bind$0040_9.ShipmentStatus, bind$0040_9.Shipper, bind$0040_9.Consignee, bind$0040_9.ShipmentFromDate, bind$0040_9.ShipmentToDate, msg.fields[0], bind$0040_9.PageSize)), state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources), singleton((dispatch_1) => {
                dispatch_1(new Msg(1, [new AsyncMsg$1(0, []), true]));
            }), new Intent(1, [])];
        default:
            return [new State(msg.fields[0](state.ShipmentSearchQuery), state.PreviousShipmentSearchQuery, state.ShipmentsDeferred, state.ExportShipments, state.Accounts, state.User, state.UserDataSources), Cmd_none(), new Intent(1, [])];
    }
}

export function render(state, dispatch) {
    let elems_25, elems_1, elems, value_24, elems_4, elems_3, value_41, elems_2, elems_7, elems_5, elems_6, elems_8, elems_9, elems_10, elems_11, elems_14, elems_13, value_135, elems_12, elems_15, value_167, elems_16, value_190, elems_17, elems_18, elems_21, elems_20, value_223, elems_19, elems_24, elems_23, value_251, elems_22, elems_33, elems_32, elems_31, elems_26, elems_27, matchValue_8, elems_30, elems_37, elems_36;
    const divider = createElement("hr", {
        className: join(" ", ["my-4"]),
    });
    let searchControls;
    const labelCss = ofArray(["is-block", "mb-1", "has-text-weight-semibold"]);
    const inputCss = ofArray(["input", "is-small", "is-fullwidth"]);
    const selectContainerCss = ofArray(["select", "is-small", "is-fullwidth"]);
    searchControls = createElement("form", createObj(ofArray([["onSubmit", (e) => {
        e.preventDefault();
        if (state.Accounts.length === 0) {
        }
        else {
            dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
        }
    }], ["className", join(" ", ["box"])], ["style", {
        minWidth: 300,
    }], (elems_25 = [createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "is-align-items-center", "mb-1"])], (elems = [createElement("label", {
        className: join(" ", ["has-text-weight-semibold"]),
        for: "trackingNumber",
        children: "Tracking Number",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "trackingNumber"], ["name", "trackingNumber"], ["type", "text"], ["maxLength", 50], ["placeholder", "Tracking Number..."], (value_24 = state.ShipmentSearchQuery.TrackingNumber, ["ref", (e_1) => {
        if (!(e_1 == null) && !equals_1(e_1.value, value_24)) {
            e_1.value = value_24;
        }
    }]), ["onChange", (ev) => {
        const value_26 = ev.target.value;
        dispatch(new Msg(0, [(query) => (new ShipmentSearchQuery(value_26, query.ModeOfTransport, query.ServiceLevel, query.PlaceOfReceiptCode, query.PlaceOfReceiptName, query.FinalDestinationCode, query.FinalDestinationName, query.PortOfLadingCode, query.PortOfLadingName, query.PortOfDischargeCode, query.PortOfDischargeName, query.AccountIds, query.HasException, query.HasPotentialException, query.SelectedShipmentDate, query.ShipmentStatus, query.Shipper, query.Consignee, query.ShipmentFromDate, query.ShipmentToDate, query.Page, query.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_4 = [createElement("label", {
        className: join(" ", labelCss),
        for: "status",
        children: "Status",
    }), createElement("div", createObj(ofArray([["className", join(" ", selectContainerCss)], (elems_3 = [createElement("select", createObj(ofArray([["className", join(" ", [])], ["id", "status"], ["name", "status"], (value_41 = state.ShipmentSearchQuery.ShipmentStatus, ["ref", (e_2) => {
        if (!(e_2 == null) && !equals_1(e_2.value, value_41)) {
            e_2.value = value_41;
        }
    }]), (elems_2 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "Any",
        children: "Any",
    })), delay(() => map_2((status) => createElement("option", {
        value: status.ShipmentStatus,
        children: status.ShipmentStatus,
    }), state.UserDataSources.ShipmentStatuses))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]), ["onChange", (e_3) => {
        const element = e_3.target;
        dispatch(new Msg(0, [(query_1) => (new ShipmentSearchQuery(query_1.TrackingNumber, query_1.ModeOfTransport, query_1.ServiceLevel, query_1.PlaceOfReceiptCode, query_1.PlaceOfReceiptName, query_1.FinalDestinationCode, query_1.FinalDestinationName, query_1.PortOfLadingCode, query_1.PortOfLadingName, query_1.PortOfDischargeCode, query_1.PortOfDischargeName, query_1.AccountIds, query_1.HasException, query_1.HasPotentialException, query_1.SelectedShipmentDate, element.value, query_1.Shipper, query_1.Consignee, query_1.ShipmentFromDate, query_1.ShipmentToDate, query_1.Page, query_1.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center", "mb-2"])], (elems_5 = [createElement("input", {
        className: join(" ", ["is-clickable"]),
        id: "Delayed",
        name: "Delayed",
        type: "checkbox",
        checked: state.ShipmentSearchQuery.HasException,
        onChange: (ev_1) => {
            const value_65 = ev_1.target.checked;
            dispatch(new Msg(0, [(query_2) => (new ShipmentSearchQuery(query_2.TrackingNumber, query_2.ModeOfTransport, query_2.ServiceLevel, query_2.PlaceOfReceiptCode, query_2.PlaceOfReceiptName, query_2.FinalDestinationCode, query_2.FinalDestinationName, query_2.PortOfLadingCode, query_2.PortOfLadingName, query_2.PortOfDischargeCode, query_2.PortOfDischargeName, query_2.AccountIds, value_65, query_2.HasPotentialException, query_2.SelectedShipmentDate, query_2.ShipmentStatus, query_2.Shipper, query_2.Consignee, query_2.ShipmentFromDate, query_2.ShipmentToDate, query_2.Page, query_2.PageSize))]));
        },
    }), createElement("label", {
        className: join(" ", ["is-clickable", "ml-2", "has-text-weight-semibold"]),
        for: "Delayed",
        children: "Delayed",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center"])], (elems_6 = [createElement("input", {
        className: join(" ", ["is-clickable"]),
        id: "PotentialDelays",
        name: "PotentialDelays",
        type: "checkbox",
        checked: state.ShipmentSearchQuery.HasPotentialException,
        onChange: (ev_2) => {
            const value_82 = ev_2.target.checked;
            dispatch(new Msg(0, [(query_3) => (new ShipmentSearchQuery(query_3.TrackingNumber, query_3.ModeOfTransport, query_3.ServiceLevel, query_3.PlaceOfReceiptCode, query_3.PlaceOfReceiptName, query_3.FinalDestinationCode, query_3.FinalDestinationName, query_3.PortOfLadingCode, query_3.PortOfLadingName, query_3.PortOfDischargeCode, query_3.PortOfDischargeName, query_3.AccountIds, query_3.HasException, value_82, query_3.SelectedShipmentDate, query_3.ShipmentStatus, query_3.Shipper, query_3.Consignee, query_3.ShipmentFromDate, query_3.ShipmentToDate, query_3.Page, query_3.PageSize))]));
        },
    }), createElement("label", {
        className: join(" ", ["is-clickable", "ml-2", "has-text-weight-semibold"]),
        for: "PotentialDelays",
        children: "Potential Delays",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress) => {
        if (keyPress.key === "Enter") {
            keyPress.preventDefault();
        }
    }], (elems_8 = [createElement("label", {
        className: join(" ", labelCss),
        for: "origin",
        children: "Place Of Receipt",
    }), Typeahead_render("shipment_place_of_receipt", "Place Of Receipt")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_1) => {
        if (keyPress_1.key === "Enter") {
            keyPress_1.preventDefault();
        }
    }], (elems_9 = [createElement("label", {
        className: join(" ", labelCss),
        for: "portOfLading",
        children: "Port Of Lading",
    }), Typeahead_render("shipment_port_of_lading", "Port Of Lading")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_2) => {
        if (keyPress_2.key === "Enter") {
            keyPress_2.preventDefault();
        }
    }], (elems_10 = [createElement("label", {
        className: join(" ", labelCss),
        for: "portOfDischarge",
        children: "Port Of Discharge",
    }), Typeahead_render("shipment_port_of_discharge", "Port Of Discharge")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_3) => {
        if (keyPress_3.key === "Enter") {
            keyPress_3.preventDefault();
        }
    }], (elems_11 = [createElement("label", {
        className: join(" ", labelCss),
        for: "destination",
        children: "Final Destination",
    }), Typeahead_render("shipment_final_destination", "Final Destination")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_14 = [createElement("label", {
        className: join(" ", labelCss),
        for: "shipmentDate",
        children: "Shipment Date",
    }), createElement("div", createObj(ofArray([["className", join(" ", selectContainerCss)], (elems_13 = [createElement("select", createObj(ofArray([["className", join(" ", [])], ["id", "shipmentDate"], ["name", "shipmentDate"], (value_135 = state.ShipmentSearchQuery.SelectedShipmentDate, ["ref", (e_4) => {
        if (!(e_4 == null) && !equals_1(e_4.value, value_135)) {
            e_4.value = value_135;
        }
    }]), (elems_12 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "",
        children: "Select...",
    })), delay(() => map_2((shipmentDate) => createElement("option", {
        value: shipmentDate.ShipmentDate,
        children: shipmentDate.ShipmentDate,
    }), state.UserDataSources.ShipmentDates))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))]), ["onChange", (e_5) => {
        const element_1 = e_5.target;
        dispatch(new Msg(0, [(query_4) => (isNullOrWhiteSpace(element_1.value) ? (new ShipmentSearchQuery(query_4.TrackingNumber, query_4.ModeOfTransport, query_4.ServiceLevel, query_4.PlaceOfReceiptCode, query_4.PlaceOfReceiptName, query_4.FinalDestinationCode, query_4.FinalDestinationName, query_4.PortOfLadingCode, query_4.PortOfLadingName, query_4.PortOfDischargeCode, query_4.PortOfDischargeName, query_4.AccountIds, query_4.HasException, query_4.HasPotentialException, element_1.value, query_4.ShipmentStatus, query_4.Shipper, query_4.Consignee, defaultOf(), defaultOf(), query_4.Page, query_4.PageSize)) : (new ShipmentSearchQuery(query_4.TrackingNumber, query_4.ModeOfTransport, query_4.ServiceLevel, query_4.PlaceOfReceiptCode, query_4.PlaceOfReceiptName, query_4.FinalDestinationCode, query_4.FinalDestinationName, query_4.PortOfLadingCode, query_4.PortOfLadingName, query_4.PortOfDischargeCode, query_4.PortOfDischargeName, query_4.AccountIds, query_4.HasException, query_4.HasPotentialException, element_1.value, query_4.ShipmentStatus, query_4.Shipper, query_4.Consignee, query_4.ShipmentFromDate, query_4.ShipmentToDate, query_4.Page, query_4.PageSize)))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_15 = [createElement("label", {
        className: join(" ", labelCss),
        for: "fromDate",
        children: "From",
    }), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "fromDate"], ["name", "fromDate"], ["disabled", isNullOrWhiteSpace(state.ShipmentSearchQuery.SelectedShipmentDate)], ["type", "date"], ["min", toString(minValue(), "yyyy-MM-dd")], ["max", toString(maxValue(), "yyyy-MM-dd")], (value_167 = toDateTimeOffsetInput(state.ShipmentSearchQuery.ShipmentFromDate), ["ref", (e_6) => {
        if (!(e_6 == null) && !equals_1(e_6.value, value_167)) {
            e_6.value = value_167;
        }
    }]), ["onChange", (e_7) => {
        const element_2 = e_7.target;
        dispatch(new Msg(0, [(query_5) => (new ShipmentSearchQuery(query_5.TrackingNumber, query_5.ModeOfTransport, query_5.ServiceLevel, query_5.PlaceOfReceiptCode, query_5.PlaceOfReceiptName, query_5.FinalDestinationCode, query_5.FinalDestinationName, query_5.PortOfLadingCode, query_5.PortOfLadingName, query_5.PortOfDischargeCode, query_5.PortOfDischargeName, query_5.AccountIds, query_5.HasException, query_5.HasPotentialException, query_5.SelectedShipmentDate, query_5.ShipmentStatus, query_5.Shipper, query_5.Consignee, isNullOrWhiteSpace(element_2.value) ? defaultOf() : toNullable(DateTimeOffset_fromString(element_2.value)), query_5.ShipmentToDate, query_5.Page, query_5.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_16 = [createElement("label", {
        className: join(" ", labelCss),
        for: "toDate",
        children: "To",
    }), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "toDate"], ["name", "toDate"], ["disabled", isNullOrWhiteSpace(state.ShipmentSearchQuery.SelectedShipmentDate)], ["type", "date"], ["min", toString(minValue(), "yyyy-MM-dd")], ["max", toString(maxValue(), "yyyy-MM-dd")], (value_190 = toDateTimeOffsetInput(state.ShipmentSearchQuery.ShipmentToDate), ["ref", (e_8) => {
        if (!(e_8 == null) && !equals_1(e_8.value, value_190)) {
            e_8.value = value_190;
        }
    }]), ["onChange", (e_9) => {
        const element_3 = e_9.target;
        dispatch(new Msg(0, [(query_6) => (new ShipmentSearchQuery(query_6.TrackingNumber, query_6.ModeOfTransport, query_6.ServiceLevel, query_6.PlaceOfReceiptCode, query_6.PlaceOfReceiptName, query_6.FinalDestinationCode, query_6.FinalDestinationName, query_6.PortOfLadingCode, query_6.PortOfLadingName, query_6.PortOfDischargeCode, query_6.PortOfDischargeName, query_6.AccountIds, query_6.HasException, query_6.HasPotentialException, query_6.SelectedShipmentDate, query_6.ShipmentStatus, query_6.Shipper, query_6.Consignee, query_6.ShipmentFromDate, isNullOrWhiteSpace(element_3.value) ? defaultOf() : toNullable(DateTimeOffset_fromString(element_3.value)), query_6.Page, query_6.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_4) => {
        if (keyPress_4.key === "Enter") {
            keyPress_4.preventDefault();
        }
    }], (elems_17 = [createElement("label", {
        className: join(" ", labelCss),
        for: "shipper",
        children: "Shipper",
    }), Typeahead_render("shipment_shipper", "Shipper")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], ["onKeyPress", (keyPress_5) => {
        if (keyPress_5.key === "Enter") {
            keyPress_5.preventDefault();
        }
    }], (elems_18 = [createElement("label", {
        className: join(" ", labelCss),
        for: "consignee",
        children: "Consignee",
    }), Typeahead_render("shipment_consignee", "Consignee")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_21 = [createElement("label", {
        className: join(" ", labelCss),
        for: "mot",
        children: "Mode Of Transport",
    }), createElement("div", createObj(ofArray([["className", join(" ", selectContainerCss)], (elems_20 = [createElement("select", createObj(ofArray([["className", join(" ", inputCss)], ["id", "mot"], ["name", "mot"], (value_223 = state.ShipmentSearchQuery.ModeOfTransport, ["ref", (e_10) => {
        if (!(e_10 == null) && !equals_1(e_10.value, value_223)) {
            e_10.value = value_223;
        }
    }]), (elems_19 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "Any",
        children: "Any",
    })), delay(() => map_2((mot) => createElement("option", {
        value: mot.ModeOfTransport,
        children: mot.ModeOfTransport,
    }), state.UserDataSources.ModesOfTransport))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))]), ["onChange", (e_11) => {
        const element_4 = e_11.target;
        dispatch(new Msg(0, [(query_7) => (new ShipmentSearchQuery(query_7.TrackingNumber, element_4.value, "", query_7.PlaceOfReceiptCode, query_7.PlaceOfReceiptName, query_7.FinalDestinationCode, query_7.FinalDestinationName, query_7.PortOfLadingCode, query_7.PortOfLadingName, query_7.PortOfDischargeCode, query_7.PortOfDischargeName, query_7.AccountIds, query_7.HasException, query_7.HasPotentialException, query_7.SelectedShipmentDate, query_7.ShipmentStatus, query_7.Shipper, query_7.Consignee, query_7.ShipmentFromDate, query_7.ShipmentToDate, query_7.Page, query_7.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_24 = [createElement("label", {
        className: join(" ", labelCss),
        for: "serviceLevel",
        children: "Service Level",
    }), createElement("div", createObj(ofArray([["className", join(" ", selectContainerCss)], (elems_23 = [createElement("select", createObj(ofArray([["className", join(" ", inputCss)], ["id", "serviceLevel"], ["name", "serviceLevel"], ["disabled", isNullOrWhiteSpace(state.ShipmentSearchQuery.ModeOfTransport) ? true : (compare_1(state.ShipmentSearchQuery.ModeOfTransport, "Any", 5) === 0)], (value_251 = state.ShipmentSearchQuery.ServiceLevel, ["ref", (e_12) => {
        if (!(e_12 == null) && !equals_1(e_12.value, value_251)) {
            e_12.value = value_251;
        }
    }]), (elems_22 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "",
        children: "Any",
    })), delay(() => map_2((serviceLevel) => createElement("option", {
        value: serviceLevel.Name,
        children: serviceLevel.Name,
    }), serviceLevelSearch(state)))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))]), ["onChange", (e_13) => {
        const element_5 = e_13.target;
        dispatch(new Msg(0, [(query_8) => (new ShipmentSearchQuery(query_8.TrackingNumber, query_8.ModeOfTransport, element_5.value, query_8.PlaceOfReceiptCode, query_8.PlaceOfReceiptName, query_8.FinalDestinationCode, query_8.FinalDestinationName, query_8.PortOfLadingCode, query_8.PortOfLadingName, query_8.PortOfDischargeCode, query_8.PortOfDischargeName, query_8.AccountIds, query_8.HasException, query_8.HasPotentialException, query_8.SelectedShipmentDate, query_8.ShipmentStatus, query_8.Shipper, query_8.Consignee, query_8.ShipmentFromDate, query_8.ShipmentToDate, query_8.Page, query_8.PageSize))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])]))), divider, createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("block"), delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-primary"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals_1(state.ShipmentsDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty())))))))))))))),
        type: "submit",
        children: "Search",
        disabled: state.Accounts.length === 0,
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("block"), delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals_1(state.ShipmentsDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty())))))))))))),
        type: "button",
        onClick: (_arg) => {
            dispatch(new Msg(8, []));
        },
        children: "Clear",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])])));
    const header = createElement("div", createObj(ofArray([["className", join(" ", ["block"])], (elems_33 = [createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_32 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-align-items-center"])], (elems_31 = [createElement("div", createObj(ofArray([["className", "column"], (elems_26 = [createElement("h1", {
        className: join(" ", ["has-text-weight-semibold", "has-text-link", "is-size-5"]),
        children: "Shipments",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_27 = [createElement("div", {
        className: join(" ", ["has-text-weight-semibold"]),
        children: (matchValue_8 = state.ShipmentsDeferred, (matchValue_8.tag === 1) ? "Searching..." : ((matchValue_8.tag === 2) ? ((matchValue_8.fields[0].tag === 0) ? ((compare(matchValue_8.fields[0].fields[0].TotalCount, toInt64(fromInt32(0))) > 0) ? (`${formatInt(~~toInt32(matchValue_8.fields[0].fields[0].TotalCount))} shipments found | showing page ${formatInt(matchValue_8.fields[0].fields[0].Page)} of ${formatInt(matchValue_8.fields[0].fields[0].NumberOfPages)}`) : "0 shipments found") : "") : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])]))), createElement("div", createObj(ofArray([["className", "column is-narrow"], (elems_30 = toList(delay(() => {
        let elems_28;
        return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "mr-2"])], ["type", "button"], ["disabled", !hasPreviousPage(state)], ["onClick", (_arg_1) => {
            if (hasPreviousPage(state)) {
                dispatch(new Msg(10, [state.PreviousShipmentSearchQuery.Page - 1]));
            }
        }], (elems_28 = [createElement("span", {
            className: join(" ", ["material-icons", "is-small"]),
            children: "navigate_before",
        }), createElement("span", {
            className: "is-sr-only",
            children: "Previous",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])])))), delay(() => {
            let elems_29;
            return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "mr-2"])], ["type", "button"], ["disabled", !hasNextPage(state)], ["onClick", (_arg_2) => {
                if (hasNextPage(state)) {
                    dispatch(new Msg(10, [state.PreviousShipmentSearchQuery.Page + 1]));
                }
            }], (elems_29 = [createElement("span", {
                className: join(" ", ["material-icons", "is-small"]),
                children: "navigate_next",
            }), createElement("span", {
                className: join(" ", ["is-sr-only"]),
                children: "Next",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])])))), delay(() => (state.User.CanExportDocuments ? singleton_1(createElement("button", {
                className: join(" ", ["button", "is-small"]),
                type: "button",
                children: (state.ExportShipments.tag === 1) ? "Exporting..." : "Export",
                disabled: equals_1(state.ExportShipments, new Deferred$1(1, [])) ? true : isExportDisabled(state),
                onClick: (_arg_3) => {
                    dispatch(new Msg(9, [new AsyncMsg$1(0, [])]));
                },
            })) : empty())));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])])));
    let searchResult;
    const matchValue_10 = state.ShipmentsDeferred;
    searchResult = ((matchValue_10.tag === 1) ? render_1() : ((matchValue_10.tag === 2) ? ((matchValue_10.fields[0].tag === 0) ? render_2(false, state.User.CanViewCO2Emissions, matchValue_10.fields[0].fields[0].Data) : defaultOf_1()) : defaultOf_1()));
    if (state.Accounts.length === 0) {
        const children = singleton(createElement("h4", {
            className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-5"]),
            children: "Please select a client account",
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
    else {
        return createElement("div", createObj(singleton((elems_37 = [header, createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_36 = [createElement("div", {
            className: join(" ", ["column", "is-narrow"]),
            children: Interop_reactApi.Children.toArray([searchControls]),
        }), createElement("div", {
            className: join(" ", ["column"]),
            children: Interop_reactApi.Children.toArray([searchResult]),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_36))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_37))]))));
    }
}

