import { typeahead } from "./Typeahead.js";
import { join, printf, toText } from "../fable_modules/fable-library.4.10.0/String.js";
import { AsyncResult_toPromise } from "../Extensions.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";

export const Interop_typeahead = typeahead;

export function Typeahead_setInputValue(id, value) {
    const element = document.getElementById(toText(printf("%s_typeahead_input"))(id));
    if (!(element == null)) {
        element.value = value;
    }
}

export function Typeahead_init(id, getDataSource, handler, delay, inputValueMinLength) {
    return singleton((dispatch) => {
        Interop_typeahead(toText(printf("%s_typeahead_input"))(id), toText(printf("%s_typeahead_menu"))(id), (input) => AsyncResult_toPromise(getDataSource, input), (selectedItem) => {
            dispatch(handler(selectedItem));
        }, delay, inputValueMinLength);
    });
}

export function Typeahead_render(id, placeHolder) {
    let elems_3, elems_1, elems, elems_2;
    return createElement("div", createObj(ofArray([["className", join(" ", ["typeahead", "dropdown"])], (elems_3 = [createElement("div", createObj(ofArray([["className", "dropdown-trigger"], (elems_1 = [createElement("div", createObj(ofArray([["className", "control is-small"], (elems = [createElement("input", {
        id: toText(printf("%s_typeahead_input"))(id),
        className: "input is-small is-fullwidth",
        type: "text",
        placeholder: toText(printf("Enter %s"))(placeHolder),
        autoComplete: "off",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "dropdown-menu"], ["id", toText(printf("%s_typeahead_menu"))(id)], ["role", join(" ", ["menu"])], (elems_2 = [createElement("div", {
        className: "dropdown-content",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

